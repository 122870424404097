import React from "react"

function Redirect({url}) {
  return (
    <>
      <p>Redirecting...</p>
      <meta http-equiv="refresh" content={`0;URL='${url}'`} />
    </>
  )
}
export default Redirect
